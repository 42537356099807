.item_button {
	display: flex;
	width: 20%;
	background-color: #262932;
	justify-content: center;
	padding: 5px 10px;
	border-radius: 5px;
	color: white;
	cursor: pointer;

	&:hover {
		background-color: #3f4042;
	}

	@media (max-width: 575px) {
		width: 100%;
	}
}

.item_table {
	margin-top: 10px;

	.item_item {
		background-color: #dee2e6;
		padding: 10px 10px;
		border-radius: 5px;
		margin-bottom: 8px;
		display: flex;

		.first {
			display: flex;
			align-items: center;
			width: 5%;
			color: #262932;
			font-weight: bold;
		}

		.second {
			display: flex;
			align-items: center;
			width: 80%;
		}

		.third {
			display: flex;
			align-items: center;
			width: 15%;

			.icon {
				width: 2em;
				height: 2em;
				margin-right: 5px;
				cursor: pointer;
			}
		}
	}
}
