.buttonAdd {
	margin-bottom: 10px;
}

.buttonDelete {
	fill: tomato;
	width: 1.5em;
	height: 1.5em;
	margin-top: 7px;
	cursor: pointer;
}

.new_question {
	margin-top: 7px;
	display: flex;
	width: 100%;

	.description {
		width: 70%;
	}

	.icon {
		width: 2em;
		height: 2em;
		stroke: white;
	}

	.div_icon {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: 5px;
		margin: 1px 5px;
		cursor: pointer;

		&.check {
			background-color: #51bb25;

			&:hover {
				background-color: #1d420e;
			}
		}

		&.times {
			background-color: #dc3545;

			&:hover {
				background-color: #46090f;
			}
		}
	}
}

.show_questions {
	background-color: #0e75ba;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
}
