.item_button {
	display: flex;
	width: 20%;
	background-color: #262932;
	justify-content: center;
	padding: 5px 10px;
	border-radius: 5px;
	color: white;
	margin-bottom: 5px;
	cursor: pointer;

	&:hover {
		background-color: #3f4042;
	}

	@media (max-width: 575px) {
		width: 100%;
	}
}

.icon {
	cursor: pointer;
	width: 2em;
	height: 2em;
}

.cardHeader {
	padding: 15px 20px !important;
	background-color: #598bc8 !important;
	color: white !important;
	border-bottom: 1px solid #ecf3fa !important;
	border-top-left-radius: 15px !important;
	border-top-right-radius: 15px !important;
	position: relative !important;
	cursor: pointer;

	background-image: url("../../../assets/icons/down.svg");
	background-position: right;
	background-repeat: no-repeat;
	background-size: 40px 20px;

	&.active {
		background-color: #0e75ba !important;
		background-image: url("../../../assets/icons/up.svg");
	}
}

.cardBody {
	padding: 15px !important;

	.header {
		display: flex;
		font-size: 13px;
		font-weight: 500;
		text-align: center;

		.header_date {
			width: 15%;
		}
		.header_time {
			width: 10%;
		}
		.header_function {
			width: 55%;
		}
		.header_auditor {
			width: 15%;
		}
	}

	.body {
		display: flex;
		background-color: #dee2e6;
		margin-top: 10px;
		padding: 15px 5px;
		border-radius: 5px;

		.body_date {
			width: 15%;
		}
		.body_time {
			width: 10%;
		}
		.body_function {
			width: 55%;
		}
		.body_auditor {
			width: 15%;
		}

		.body_action {
			width: 5%;
			display: flex;
			justify-content: center;
			align-items: center;

			.icon {
				width: 2em;
				height: 2em;
				cursor: pointer;
			}
		}
	}
}

.cardFooter {
	padding: 10px 15px !important;
}

.buttonAdd {
	height: 3em;
	width: 3em;
	cursor: pointer;
}

.thead_staff {
	text-align: center;
	padding: 5px 5px;
	background-color: #598bc8;
	color: white;
	font-size: 13px;
}
