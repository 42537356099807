.divActivate {
	display: flex;
	justify-content: center;

	.activeButtom {
		width: 50%;
		padding: 0.5em;
		border-radius: 5px;
		text-align: center;
		font-size: 15px;
		border: 1px solid #598bc8;
		color: #598bc8;
		cursor: pointer;

		&:hover {
			background-color: #598bc8;
			color: white;
		}
	}
}

.tablePeriods {
	width: 100%;
	text-align: center;
	margin-top: 10px;
}

.divButtons {
	display: flex;
	justify-content: center;

	.buttonActive {
		width: 50%;
		padding: 1em;
		text-align: center;
		border-radius: 5px;
		color: white;
		background-color: #28a745;
		cursor: pointer;
	}

	.buttonInactive {
		width: 50%;
		padding: 1em;
		text-align: center;
		border-radius: 5px;
		color: white;
		background-color: #dd1f1f;
		cursor: pointer;
	}
}
