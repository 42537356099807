.base_error {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100%;
	text-align: center;

	.image {
		width: 30em;
	}

	.message {
		margin-top: 2em;
		padding: 20px;
	}
}

.base_error_buttom {
	display: flex;
	justify-content: center;
	margin-top: 1em;

	.button_back {
		width: 30%;
		background: #0e75ba;
		padding: 1em;
		border-radius: 5px;
		text-align: center;
		color: white;
		cursor: pointer;

		&:hover {
			background: #0e5b8f;
		}
	}
}
