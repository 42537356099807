// TABLE
.base-div {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	padding-bottom: 10px;
}

.base-table {
	width: 100%;
	border-collapse: collapse;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border-style: hidden;
	box-shadow: 0 0 0 1px $primary-color;
}

.base-table td,
.base-table th {
	border: 1px solid #ddd;
	padding: 8px;
}

.base-table th {
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: left;
	background-color: $primary-color;
	color: white;
	text-align: center;
	font-weight: 400;
}

.base-table th:last-child {
	border-top-right-radius: 10px;
}

.base-table th:first-child {
	border-top-left-radius: 10px;
}

.base-table {
	.table-actions {
		cursor: pointer;

		.icon-delete {
			width: 1.5em;
			height: 1.5em;
			fill: #e4566e;
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
		.icon-edit {
			width: 1.5em;
			height: 1.5em;
			fill: rgb(40, 167, 69);
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
	}
}

// CARDS
.card-body {
	padding: 30px !important;
}

.form-tab-detail {
	margin-top: 1em;
}

// PAGINADOR
.base-paginator {
	padding-bottom: 1em;
}

// FETCHING
.fetching-data {
	text-align: center;
	padding: 3em;
	color: $primary-color !important;
}

// MODAL
.modal-title {
	font-size: 1em !important;
}

// LIST
.header-table {
	margin-bottom: 1em;

	.label-pagination {
		font-size: 15px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
	}
}

.pagination-right {
	float: right;
}

.main-action {
	margin-bottom: 5px;
}

.action {
	background-color: $primary-color;
	border-radius: 10px;
	padding: 0.5em;
	text-align: center;
	margin: auto;
	color: white;
	font-weight: 500;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-content: center;

	.icon {
		width: 1.5em;
		height: 1.5em;
		margin-left: 5px;
		margin-right: 5px;
		fill: white;
	}

	&:hover {
		background-color: $secondary-color;
		color: white;
	}
}

.base-ckeditor {
	width: 100%;

	figure {
		width: 100%;

		div {
			width: 100%;
		}
	}
}

.upload-doc {
	display: flex;
	width: 25%;
	background-color: #0e75ba;
	color: white;
	cursor: pointer;
	padding: 10px 15px;
	border-radius: 5px;

	&:hover {
		background-color: #0a4c78;
	}

	@media (max-width: 575px) {
		width: 100%;
	}
}

.new_question {
	display: flex;
	width: 25%;
	background-color: #0e75ba;
	color: white;
	cursor: pointer;
	padding: 10px 15px;
	border-radius: 5px;

	&:hover {
		background-color: #0a4c78;
	}

	@media (max-width: 575px) {
		width: 100%;
	}
}

.table-docs {
	display: flex;
	flex-direction: column;

	.table-item {
		width: 100%;
		display: flex;
		background: #b4c3e6;
		margin-top: 5px;
		padding: 8px 10px;
		border-radius: 5px;

		.index {
			color: #0a4c78;
			width: 3%;
			font-weight: bold;
			display: flex;
			align-items: center;
		}

		.name {
			width: 77%;
			display: flex;
			align-items: center;
		}

		.actions {
			width: 20%;

			.file-icons {
				width: 2em;
				height: 2em;
				cursor: pointer;
				margin: 0px 5px;
			}
		}
	}
}

.no-data {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;

	.no-data-text {
		font-size: 25px;
		color: #01364b;
	}

	.no-data-icon {
		width: 80vh;
		height: 70vh;
	}
}

@media (max-width: 575px) {
	.label-pagination {
		text-align: right;
	}
}

@media (max-width: 640px) {
	.responsiveTable tbody tr {
		border: initial !important;
		border-bottom: 1px solid $primary-color !important;
		text-align: right;
		border-collapse: collapse;
		border-top-right-radius: 30px;
		border-top-left-radius: 30px;
	}
}
